<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form">
      <!--      <a-form-model-item label="店铺id" prop="shopId" >-->
      <!--        <a-input v-model="form.shopId" placeholder="请输入店铺id" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="营业执照" prop="businessLicenseImage">
        <oss-multi-upload v-model="form.businessLicenseImage" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="店铺名称" prop="shopName">
        <a-input v-model="form.shopName" placeholder="请输入店铺名称" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="法人名称" prop="legalPerson" >
        <a-input v-model="form.legalPerson" placeholder="请输入法人名称" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="mobile">
        <a-input v-model="form.mobile" placeholder="请输入联系电话" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="统一社会信用代码" prop="businessLicenseUSCI" >
        <a-input v-model="form.businessLicenseUSCI" placeholder="请输入统一社会信用代码" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="店铺logo" prop="logo">
        <oss-multi-upload v-model="form.logo" type="img" :maxCount="0" :maxSize="10" :isRemove="false"></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="省" prop="areap">
        <a-input v-model="form.areap" placeholder="请输入所在地区" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="市" prop="areac">
        <a-input v-model="form.areac" placeholder="请输入所在地区" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="区" prop="areax">
        <a-input v-model="form.areax" placeholder="请输入所在地区" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="地区编码" prop="areaCode">
        <a-input v-model="form.areaCode" placeholder="请输入地区编码" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="form.address" placeholder="请输入详细地址" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="经度" prop="longitude">
        <a-input v-model="form.longitude" placeholder="请输入经度" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="纬度" prop="latitude">
        <a-input v-model="form.latitude" placeholder="请输入纬度" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="form.address" placeholder="请输入详细地址" :disabled="true"/>
      </a-form-model-item>
<!--      <a-form-model-item label="审核状态" prop="status">-->
<!--        <a-select style="width: 250px" v-model="form.status" placeholder="请输入审核状态">-->
<!--          <a-select-option v-for="(item, index) in this.customDict.ShopAuditStatusEnum" :value="item.type" :key="index">-->
<!--            {{ item.name }}-->
<!--          </a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->

      <!--      <a-form-model-item label="驳回原因" prop="reason" >-->
      <!--        <a-input v-model="form.reason" placeholder="请输入内容" type="textarea" allow-clear />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="提交修改时间" prop="editTime">
        <a-date-picker style="width: 100%" v-model="form.editTime" format="YYYY-MM-DD HH:mm:ss" allow-clear :disabled="true"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark" >
              <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
            <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
              <a-input v-model="form.version" placeholder="请输入乐观锁" />
            </a-form-model-item>-->
      <a-modal v-model="auditVisible" :loading="submitLoading" title="提交审批" @ok="submitAudit">
        <a-select style="width: 250px" v-model="auditStatus" placeholder="请输入审核状态">
<!--          <a-select-option v-for="(item, index) in this.customDict.ShopAuditStatusEnum" :value="item.type" :key="index">-->
<!--            {{ item.name }}-->
<!--          </a-select-option>-->
          <a-select-option value="3">通过</a-select-option>
          <a-select-option value="4">驳回</a-select-option>
        </a-select>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="auditVisible = true">
            提交审核
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getShopRecord, addShopRecord, updateShopRecord, auditShopInfoStatus } from '@/api/shop/shopRecord'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {},
  components: {
    OssMultiUpload
  },
  data() {
    return {
      auditVisible: false,
      submitLoading: false,
      auditStatus: '3',
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        shopId: null,
        legalPerson: null,
        businessLicenseImage: null,
        mobile: null,
        businessLicenseUSCI: null,
        logo: null,
        shopName: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        areap: null,
        areac: null,
        areax: null,
        address: null,
        status: 0,
        reason: null,
        editTime: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        editTime: [
          { required: true, message: '提交修改时间不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        shopId: null,
        legalPerson: null,
        businessLicenseImage: null,
        mobile: null,
        businessLicenseUSCI: null,
        logo: null,
        shopName: null,
        areaCode: null,
        longitude: null,
        latitude: null,
        areap: null,
        areac: null,
        areax: null,
        address: null,
        status: 0,
        reason: null,
        editTime: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopRecord(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShopRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShopRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    submitAudit() {
      if (this.form.id !== undefined && this.form.id !== null) {
        auditShopInfoStatus({
          id: this.form.id,
          status: this.auditStatus
        }).then(response => {
          this.$message.success(
            '审核成功',
            3
          )
          this.$emit('ok')
        }).finally(() => {
          this.open = false
          this.auditStatus = '3'
          this.$emit('ok')
          this.auditVisible = false
          this.submitLoading = false
        })
      }
    }
  }
}
</script>
