<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="审核状态" prop="status">
                <a-select placeholder="请选择审核状态" style="width: 100%" v-model="queryParam.status" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ShopAuditStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons"
                    :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search"/>查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo"/>重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['shop:shop/record:add']">
                  <a-icon type="plus" />新增
                </a-button>
                <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['shop:shop/record:edit']">
                  <a-icon type="edit" />修改
                </a-button>
                <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['shop:shop/record:remove']">
                  <a-icon type="delete" />删除
                </a-button>
                <a-button type="primary" @click="handleExport" v-hasPermi="['shop:shop/record:export']">
                  <a-icon type="download" />导出
                </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"/>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="editTime" slot-scope="text, record">
          {{ parseTime(record.editTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
<!--          <a-divider type="vertical" v-hasPermi="['shop:shop/record:edit']"/>-->
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['shop:shop/record:edit']">
            <a-icon type="edit"/>修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['shop:shop/record:remove']"/>-->
<!--          <a @click="handleDelete(record)" v-hasPermi="['shop:shop/record:remove']">-->
<!--            <a-icon type="delete"/>删除-->
<!--          </a>-->
        </span>
        <span slot="status" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（标签）-->
           <custom-dict-tag :options="customDict.ShopAuditStatusEnum" :value="record.status"/>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {listShopRecord, delShopRecord} from '@/api/shop/shopRecord'
import CreateForm from './modules/CreateForm'
import {tableMixin} from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'ShopRecord',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        shopId: null,
        logo: null,
        shopName: null,
        areaCode: null,
        areap: null,
        areac: null,
        areax: null,
        address: null,
        status: null,
        reason: null,
        editTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /* {
           title: 'id',
           dataIndex: 'id',
           ellipsis: true,
           align: 'center'
         },
         {
           title: '店铺id',
           dataIndex: 'shopId',
           ellipsis: true,
           align: 'center'
         },*/
        // {
        //   title: '店铺logo',
        //   dataIndex: 'logo',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '店铺名称',
          dataIndex: 'shopName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所在地区编码',
          dataIndex: 'areaCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '省',
          dataIndex: 'areap',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '市',
          dataIndex: 'areac',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '区',
          dataIndex: 'areax',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '驳回原因',
        //   dataIndex: 'reason',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '提交修改时间',
          dataIndex: 'editTime',
          scopedSlots: {customRender: 'editTime'},
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: {customRender: 'operation'},
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    /** 查询店铺名称logo等修改记录审核信息列表 */
    getList() {
      this.loading = true
      listShopRecord(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        shopId: undefined,
        logo: undefined,
        shopName: undefined,
        areaCode: undefined,
        areap: undefined,
        areac: undefined,
        areax: undefined,
        address: undefined,
        status: undefined,
        reason: undefined,
        editTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delShopRecord(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
            })
        },
        onCancel() {
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download('shop/shop/record/export', {
            ...that.queryParam
          }, `shop/record_${new Date().getTime()}.xlsx`)
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
